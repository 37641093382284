.aboutPara {
    /* text-align: justify; */


    /* line-height: 30px; */
    padding: 5px;
}

.googlemap {
    /* width: %; */
    height: 100%;
    width: 50%;
    /* margin: 0 auto; */
    display: block;
    margin-left: auto;
    margin-right: auto;
}


.rgbutton  {
    display:flex;
    justify-content: center;
}

.myhero {
    text-align: center;
    /* display: grid;
    grid-template-areas: 
        "left righttop"
        "left rightbottom";
    grid-template-columns: 6fr 4fr; */
}

.message {
    color: rgb(168, 243, 193);
    /* padding: 0.85rem 1.9rem; */
    line-height: 1.5;

  font-size: .7rem;
  text-align: center;
  justify-content: center;
  margin-top: .9rem;
}

.acuitybutton {
    background: #5fb8b9; 
    background-color: #111111 !important;
    color: #fff; 
    padding: 0px 0px; 
    border: 0px; 
    -webkit-box-shadow: 0 -2px 0 rgba(0,0,0,0.15) inset;
    -moz-box-shadow: 0 -2px 0 rgba(0,0,0,0.15) inset;
    box-shadow: 0 -2px 0 rgba(0,0,0,0.15) inset;
    border-radius: 4px; 
    text-decoration: none; 
    display: inline-block;
}